import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "indicator" }
const _hoisted_2 = { class: "indicator__name" }
const _hoisted_3 = { class: "indicator__value" }
const _hoisted_4 = {
  key: 1,
  class: "row mt-2 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_tirscript3_loading = _resolveComponent("tirscript3-loading")!
  const _component_bs_col = _resolveComponent("bs-col")!
  const _component_bs_row = _resolveComponent("bs-row")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Текущие показатели",
    class: "current-indicator-page",
    selectSource: false
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_filter_component, {
        filter: _ctx.filterModel,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterModel) = $event)),
        onFilterData: _ctx.onclickFilterChange,
        selectData: false,
        selectLogsFilter: false,
        selectInterval: false,
        selectViewFilter: false
      }, null, 8, ["filter", "onFilterData"])
    ]),
    default: _withCtx((slotProps) => [
      _createVNode(_component_tirscript3_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indicators, (item, idx) => {
        return (_openBlock(), _createElementBlock("section", { key: idx }, [
          _createVNode(_component_bs_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (indicator, idx1) => {
                return (_openBlock(), _createBlock(_component_bs_col, { key: idx1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(indicator.Name), 1),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(indicator.Value), 1)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128)),
      (!this.indicators && !this.indicators.length > 0)
        ? (_openBlock(), _createBlock(_component_bs_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_bs_col, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Задайте источник ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.error.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}